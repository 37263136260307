<template>
  <div id="NavBar">
    <van-nav-bar :title="title" :border="false" :left-arrow="isleftarrow" :right-text="righticon?'退出系统':''" @click-left="onClickLeft"  @click-right="onClickRight" />
  </div>
</template>
<script>
  import { removeToken } from '@/utils/auth'
  import { Dialog } from 'vant';
export default {
  // title：用来显示导航栏的title，isleftarrow用来显示导航栏的左侧返回箭头
  props: ['title', 'isleftarrow','righticon'],
  components: {
      [Dialog.Component.name]: Dialog.Component,
    },
  data(){
    return{
      show:false
    }
  },
  methods: {
    onClickLeft () {
      // 点击回退的时候当做地址回退
      this.$router.go(-1)
    },
    onClickRight(){
      Dialog.confirm({
        title: '提示',
        message: '您确定要退出当前系统？',
        confirmButtonColor:'#FF5000',
        cancelButtonColor:'#aaa'
      })
        .then(() => {
          removeToken();
          this.$router.go(-1)
        })
        .catch(() => {
          // on cancel
        });
    }
  }
}
</script>
