import { createStore } from 'vuex'
import { getToken, removeToken } from '@/utils/auth'

export default createStore({
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    tim: undefined,
    socket: undefined,
    messages: {},
    online: true,
    closed: false
  },
  mutations: {
    setClosed (state, closed) {
      state.closed = closed
    },
    setTim (state, tim) {
      state.tim = tim
    },
    setSocket (state, socket) {
      state.socket = socket
    },
    setMessages (state, messages) {
      state.messages = messages
    },
    setOnline (state, online) {
      state.online = online
    }
  },
  getters: {
    getClosed: state => state.closed,
    getTim: state => state.tim,
    getSocket: state => state.socket,
    getMessages: state => state.messages,
    getOnline: state => state.online
  },
  actions: {
    // 退出系统
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        try {
          commit('SET_TOKEN', '')
          removeToken()
          resolve()
        } catch (err) {
          reject(err)
        }
      })
    }
  },
  modules: {
  }
})
