import request from '../utils/request'
// 发送验证码
export function sendsms (query) {
  return request({
    url: '/appreq/appUser/sendsms',
    method: 'GET',
    params: query,
    headers: {
      loading: false
    }
  })
}
// 手机号登录
export function phoneLogin (data) {
  return request({
    url: '/appreq/appUser/phoneLogin',
    method: 'POST',
    headers: {
      isToken: false
    },
    data: data
  })
}
// 录入
export function addUserInfo (data) {
  return request({
    url: '/appreq/appUser/addUserInfo',
    method: 'POST',
    data: data
  })
}
// 编辑
export function updateUserInfo (data) {
  return request({
    url: '/appreq/appUser/updateUserInfo',
    method: 'POST',
    data: data
  })
}
// 获取用户信息
export function getUserInfo () {
  return request({
    url: '/appreq/appUser/getUserInfo',
    method: 'GET'
  })
}
// 文件上传
export function upload (data) {
  return request({
    url: '/appreq/oss/upload',
    method: 'POST',
    data: data
  })
}

// 存入缓存

export function addCache (data) {
  return request({
    url: '/appreq/appUser/addCache',
    method: 'POST',
    data: data
  })
}
