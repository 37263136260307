<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <template v-if="navShow">
    <NavBar v-show="navShow" :title="title" :isleftarrow="isleftarrow" :righticon="righticon"></NavBar>
  </template>
  <router-view v-slot="{ Component }" >
    <keep-alive :key="$route.path">
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>
<script>
import NavBar from './common/header.vue'
export default {
  components: {
    NavBar
  },
  data () {
    return {
      title: '',
      isleftarrow: '',
      transitionName: 'fade',
      navShow: true,
      righticon:false
    }
  },
  mounted () {
    this.title = this.$route.meta.title
    this.isleftarrow = this.$route.meta.isleftarrow
    this.navShow = this.$route.meta.navShow
    this.righticon = this.$route.meta.righticon
    console.log('this.$route.meta',this.$route.meta.title)
    console.log('this.righticon',this.righticon)
    console.log('this.title',this.title)
  },
  methods: {

  },
  watch: {
    $route (to, from) {
      this.title = to.meta.title
      this.isleftarrow = to.meta.isleftarrow
      this.navShow = to.meta.navShow
      this.righticon = to.meta.righticon
      console.log('this.righticon',this.righticon)
      console.log('this.title',this.title)
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  /* min-height: 100%; */
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
