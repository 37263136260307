<template>
  <div class="login" id="login">
    <div class="logo">
      <img alt="logo" src="../../assets/images/logo.svg" />
    </div>
    <form>
      <div style="margin-bottom:30px;">
        <label>手机号登录/注册</label>
        <input
          v-model="form.username"
          placeholder="手机号登录/注册"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
      </div>
      <div style="position: relative;">
        <label>验证码</label>
        <input
          v-model="form.code"
          placeholder="请输入验证码"
          :rules="[{ required: true, message: '请输入验证码' }]"
        />
        <div class="code-btn" @click="getCode">{{codeText}}</div>
      </div>
      <div style="margin-top: 50px" @click="goLogin">
        <van-button type="primary" block round>登录</van-button>
      </div>
    </form>
  </div>
</template>

<script>
import { phoneLogin, sendsms } from '@/api/common'
import { getToken, setToken } from '@/utils/auth'
export default {
  name: 'Login',
  components: {},
  data () {
    return {
      userType: '',
      codeText: '获取验证码',
      form: {
        username: undefined,
        code: undefined
      },
      checked: false,
      tim: undefined,
      showStart: true,
      socket: undefined
    }
  },
  mounted () {
    this.checkedToken ()
  },
  methods: {
    getToken () {
      const token = getToken()
      if (token) {
        this.$router.push({ path: '/videolist' })
      }
    },
    checkPhone (val) {
      let flag = true
      if (val) {
        if (!(/^1[3456789]\d{9}$/.test(val))) {
          flag = false
        }
        return flag
      }
    },

    getUserInfo (userId) {
      var promise = getUserInfoById(userId).then((res) => {
        return res
      })
      return promise
    },
    getCode () {
      var that = this
      var timer
      var checkPhone = that.checkPhone(that.form.username)
      if (that.codeText !== '获取验证码') {
        this.$toast({
          message: '验证码已发送，请注意查收',
          icon: 'none',
          duration: 2000
        })
        return false
      };
      if (!this.form.username) {
        that.$toast({
          message: '请输入您的手机号码',
          icon: 'none',
          duration: 2000
        })
        return false
      } else if (!checkPhone) {
        that.$toast({
          message: '请输入正确的的手机号码',
          icon: 'none',
          duration: 2000
        })
        return false
      };
      sendsms({ phone: this.form.username }).then(response => {
        if (response.code === 200) {
          var ss = 59
          timer = setInterval(function () {
            ss--
            that.codeText = '验证码(' + ss + 's)'
            if (ss <= 0) {
              clearInterval(timer)
              that.codeText = '获取验证码'
            };
          }, 1000)
        } else {
          clearInterval(timer)
          that.setData({
            codeText: '获取验证码'
          })
        }
      })
    },
    goLogin () {
      var that = this
      var checkPhone = that.checkPhone(that.form.username)
      if (!that.form.username) {
        that.$toast({
          message: '请输入您的手机号码',
          icon: 'none',
          duration: 2000
        })
        return false
      } else if (!checkPhone) {
        that.$toast({
          message: '请输入正确的的手机号码',
          icon: 'none',
          duration: 2000
        })
        return false
      };
      if (!that.form.code) {
        that.$toast({
          message: '请输入验证码',
          icon: 'none',
          duration: 2000
        })
        return false
      };
      phoneLogin({phonenumber: that.form.username, code: that.form.code}).then((res)=>{
        if(res.code === 200){
          setToken(res.data.token);
          this.$router.push({ path: '/info'})
        }
      })
    },
    goTo (str) {
      this.$router.push({ path: '/protocol', query: { type: str } })
    },
    checkedToken () {
      const token = getToken()
      var that = this
      if (token) {
        this.$router.push({ path: '/info'})
      }
    },
    getAddActiveData () {
      getAddActive({ type: '2' }).then(progress => {})
    },
    getAddVisitNumData () {
      getAddVisitNum().then(progress => {})
    }
  }
}
</script>
<style>
#login .van-popup{
  overflow: hidden;
}
</style>
<style scoped>
.login{
  min-height: calc(100vh - 66px);
  background: #fff;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.logo img {
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  /* padding-top: 30px; */
}
form {
  font-size: 16px;
  color:#333333;
  line-height: 44px;
  padding: 80px 40px;
}
form label {
  display: block;
}
form input {
  display: block;
  padding-left: 15px;
  width: calc(100% - 15px);
  border-radius: 5px;
  background-color: rgba(247, 247, 247, 100);
  border: none;
}
.code-btn{
  width: 90px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: var(--themeColor);
  border-left: solid 1px var(--themeColor);
  position: absolute;
  top: 58px;
  right: 0;
  font-size:12px;
}
.nother{
  text-align: center;
}
.protocol{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.protocol span{
  padding-left: 6px;
}
a{
   color: var(--themeColor);
   text-decoration: none;
}
.van-popup{
  overflow: hidden;
}
</style>
