import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/login/index.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { title: '', isleftarrow: false, navShow: true,righticon:false },
    component: Login
  },
  // {
  //   path: '/index',
  //   name: 'Index',
  //   meta: { title: '首页', isleftarrow: false, navShow: true,righticon:true,keepAlive: true },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/index.vue')
  // },
  {
    path: '/info',
    name: 'Info',
    meta: { title: '入职信息', isleftarrow: true, navShow: true, righticon:true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/info.vue')
  },
  // {
  //   path: '/list',
  //   name: 'List',
  //   meta: { title: '入职信息', isleftarrow: true, navShow: true,keepAlive: true },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/list.vue')
  // },
  // {
  //   path: '/detail/:id/',
  //   name: 'Detail',
  //   meta: { title: '入职信息', isleftarrow: true, navShow: true, keepAlive: true },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/detail.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
